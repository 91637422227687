import { useRef, useState } from 'react';
import NextLink from 'next/link';
import {
  
  IconButton,
  
  SvgIcon,
  Tooltip,
  
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
  
  Money
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'src/store';




const Accounting = () => {
  
  return (
    <NextLink href="/app/accounting" passHref legacyBehavior>
      <Tooltip title="Accounting">
        <IconButton color="inherit" size="large">
          <SvgIcon>
            <Money />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </NextLink>
  );
};

export default Accounting;
