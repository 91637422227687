//import NextLink from 'next/link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useAuth from 'src/hooks/useAuth';
import BHALogin from './BHALogin';
import LogoButton from 'src/components/LogoButton';
import Head from 'next/head';
import useSettings from 'src/hooks/useSettings';



const LoginView = () => {
  
  const { method } = useAuth();
  const {settings} = useSettings()
  

  return  <>
    <Head>
      <title>Login | BANKAYO</title>
    </Head>
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="sm" sx={{ py: "80px" }}>
      <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 4,
            
          }}
        >
          

          <Typography color="textPrimary" variant="h4">
                {settings.fullname?settings.fullname:"BANKAYO"}
                </Typography>
            
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 8,
            
          }}
        >
          

            <LogoButton/>
            
        </Box>
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <div>
                <Typography  color="textSecondary" gutterBottom variant="body2">
                  Please Login
                </Typography>
                
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              {/* {method === "Amplify" && <LoginAmplify />}
              {method === "Auth0" && <LoginAuth0 />}
              {method === "Firebase" && <LoginFirebase />}
              {method === "JWT" && <LoginJWT />} */}
              {method === "BHA" && <BHALogin />}
            </Box>
            <Divider sx={{ my: 3 }} />
            

            {/* {method === "Amplify" && (
              <NextLink href="/authentication/password-recovery" passHref legacyBehavior>
                <Link color="textSecondary" sx={{ mt: 1 }} variant="body2">
                  Forgot password
                </Link>
              </NextLink>
            )} */}
          </CardContent>
        </Card>
      </Container>
    </Box>
  </>;
  
};

export default LoginView;
