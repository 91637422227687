import { useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import BHALogin from "src/views/auth/LoginView";


const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useRouter();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.user ) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <BHALogin />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    location.push(requestedLocation);
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
