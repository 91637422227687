import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';


import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import SvgIcon from '@mui/material/SvgIcon';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Users as UsersIcon } from 'react-feather';
import MemberSearch from './ClientSearch';
import ClientItem from './ClientItem';
import { useGetClientsQuery, useGetGroupsQuery } from 'src/services/api';



const Clients = () => {
  

  
  const {data:clientData,error:errorLoadingClients,isLoading:isLoadingClients,isFetching:isFetchingClients} = useGetClientsQuery();
  const {data:groups=[],error:errorLoadingGroups, isLoading:isLoadingGroups, isFetching:isFetchingGroups} = useGetGroupsQuery();
  const history = useRouter();
  const [showAll,setShowAll] = useState()
  const [isSearchFocused, setSearchFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  

  const modifiedGroups = groups.map((a) => ({
    ...a,
    displayName: a.name,
    isGroup: true
  }));
  const membersToDisplay = 
  [...(clientData?clientData.pageItems:[]), ...modifiedGroups]
  .filter(a => showAll?true: a.active).slice(0, 10);
  const allMembers = [...(clientData?clientData.pageItems:[]), ...modifiedGroups].filter(a => showAll?true: a.active);

  const toggleShowAll = () =>{
    setShowAll(a => !a)
  }

  const handleSearchClickAway = () => {
    setSearchFocused(false);
    setSearchQuery('');
  };

  const handleSearchChange = async (event) => {
    try {
      event.persist();

      const { value } = event.target;

      setSearchQuery(value);

      if (value && value.length >= 2) {
        const searchString = value.toLowerCase();
        setSearchResults(
          allMembers
            .filter(
              (m) =>
              (m.fullname &&
                m.fullname.toLowerCase().indexOf(searchString) > -1) ||
              (m.displayName &&
                m.displayName.toLowerCase().indexOf(searchString) > -1) ||
              (m.middlename &&
                m.middlename.toLowerCase().indexOf(searchString) > -1) ||
                (m.firstname &&
                  m.firstname.toLowerCase().indexOf(searchString) > -1) ||
                (m.lastname &&
                  m.lastname.toLowerCase().indexOf(searchString) > -1) ||
                (m.accountNo &&
                  m.accountNo.toLowerCase().indexOf(searchString) > -1) ||
                (m.mobileNo &&
                    m.mobileNo.toLowerCase().indexOf(searchString) > -1) ||
                (m.externalId
                  ? m.externalId.toLowerCase().indexOf(searchString) > -1
                  : false)
            )
            .slice(0, 10)
        );
      } else {
        setSearchResults([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchSelect = (result) => {
    setSearchFocused(false);
    setSearchQuery('');
    history.push(`/app/management/clients/${result.id}`);
  };

  const handleSearchFocus = (event) => {
    event.persist();
    setSearchFocused(true);
  };

  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title="Customers">
      <IconButton color="inherit" onClick={handleOpen} ref={ref} size="large">
        <SvgIcon fontSize="small">
          <UsersIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      PaperProps={{
        sx: {
          p: 2,
          width: 320
        }
      }}
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
    >
      <>
      {isLoadingClients || isLoadingGroups || isFetchingClients || isFetchingGroups &&
      <Box>
        <Typography>Loading Customers, Please wait...</Typography>
        <Skeleton/>
      </Box>
       }
       {
        errorLoadingClients || errorLoadingGroups  ? <Box>
          {errorLoadingClients && <Alert severity="error" sx={{mb:1}}>{errorLoadingClients.data && errorLoadingClients.data.errors?errorLoadingClients.data.errors[0].defaultUserMessage:"Network Error" }</Alert>}
          {errorLoadingGroups && <Alert severity="error">{errorLoadingGroups.data && errorLoadingGroups.data.errors?errorLoadingGroups.data.errors[0].defaultUserMessage:"Network Error" }</Alert>}
      </Box>:null
       }
      {clientData && groups && <>
        <Box display={"flex"} flexDirection={"row"}>
      <Typography variant="h6" color="textPrimary">
        Customers
      </Typography>
      <Box flex={1}/>
      <FormControlLabel control={<Switch defaultChecked={showAll} onChange={toggleShowAll}/>} label="Include Inactive"/>
      </Box>
      
      <Box mt={2}>
        <MemberSearch
          isFocused={isSearchFocused}
          onChange={handleSearchChange}
          onClickAway={handleSearchClickAway}
          onFocus={handleSearchFocus}
          onSelect={handleSearchSelect}
          query={searchQuery}
          results={searchResults}
        />
        <List
          disablePadding
          
        >
          {searchQuery.length?<></>:membersToDisplay.map((client, i) => {
            return <ClientItem client={client} key={i} />;
          })}
          
        </List>
      </Box>

      </>}
      </>
    </Popover>
  </>;
};

export default Clients;
