import NextLink from "next/link";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { experimentalStyled } from "@mui/material/styles";
import MenuIcon from "../../../icons/Menu";
import Institution from "./Institution";
import Organization from "./Admin";
import AccountPopover from "./Account";
import Accounting from "./Accounting";
import Reports from "./Reports";
import Clients from "./Clients";
import InboxTray from "./InboxTray";
import Notifications from "./Notifications";
import LogoButton from "src/components/LogoButton";
import AutocompleteComponent from "./AutocompleteSearchComponent";


const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
          size="large">
          <MenuIcon fontSize="small" />
        </IconButton>
        <NextLink href="/" style={{textDecoration:"none"}}>

          <LogoButton/>

          {/* <Logo
            sx={{
              display: {
                lg: "inline",
                xs: "none",
              },
              height: 40,
              width: 40,
            }}
          /> */}

        </NextLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        
        
        <Box sx={{ ml: 1 }}>
          <AutocompleteComponent />
        </Box>
        <Hidden mdDown>
        <Box sx={{ ml: 1 }}>
          <Institution />
        </Box>
        </Hidden>
        <Hidden mdDown>
        <Box sx={{ ml: 1 }}>
          <Organization />
        </Box>
        </Hidden>
        <Hidden mdDown>
        <Box sx={{ ml: 1 }}>
          <Accounting />
        </Box>
        </Hidden>
        <Box sx={{ ml: 1 }}>
          <Reports />
        </Box>
        <Box sx={{ ml: 1 }}>
          <Clients />
        </Box>
        <Box sx={{ ml: 1 }}>
          <InboxTray />
        </Box>
        <Box sx={{ ml: 1 }}>
          <Notifications />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
