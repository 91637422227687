import {  useMemo, useRef, useState } from 'react';

import {
  
  Box,
  
  IconButton,
  
  Popover,
  
  Tooltip,
  Typography,
  Badge,
  Button,
  
  
} from '@mui/material';

import {
  Bell as BellIcon, Check,
  
} from 'react-feather';

import {  isAfter } from 'date-fns';
import { useGetNotificationsQuery, useGetUsersQuery, useUpdateNotificationsMutation } from 'src/services/api';
import NotificationsList from './NotificationsList';

const NotificationsReader = () =>{
  const {data:notificationsData} = useGetNotificationsQuery(undefined,{pollingInterval:120000});
  const {data:users=[]} = useGetUsersQuery();
   

  return <NotificationsContainer notificationsData={notificationsData} users={users}/>
}


const NotificationsContainer = ({notificationsData,users}) =>{
  
  
  const [notificationsToUse,setNotificationsToUse] = useState(notificationsData?notificationsData.pageItems.slice().sort((a,b)=> isAfter(new Date(a.createdAt),new Date(b.createdAt))? -1: 1).map(a => ({...a, user: users.find(b => b.id === a.actorId)})):[])
  const [markAllAsRead] = useUpdateNotificationsMutation()

  useMemo(() =>{
    setNotificationsToUse(notificationsData?notificationsData.pageItems.slice().sort((a,b)=> isAfter(new Date(a.createdAt),new Date(b.createdAt))? -1: 1).map(a => ({...a, user: users.find(b => b.id === a.actorId)})):[])
  },[setNotificationsToUse,notificationsData,users])

  

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  
  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  

  
  

  const followNotification = notification =>{
    
    router.push(`${urls[notification.objectType]}${notification.objectId}`);
  }

  const doMarkAllAsRead = ()=>{
    markAllAsRead()
    setNotificationsToUse([])
  }

  return <>
  <Tooltip title="Notifications">
      <IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="large">
        <Badge
          color="error"
          badgeContent={notificationsToUse.length}
        >
          <BellIcon fontSize="small" />
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 320 }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Notifications
        </Typography>
      </Box>
      {notificationsToUse.length === 0
        ? (
          <Box sx={{ p: 2 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              There are no notifications
            </Typography>
          </Box>
        )
        : (
          <NotificationsList notificationsToUse={notificationsToUse}/>
        )}
        <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    p: 1
  }}
>
  {notificationsToUse.length ? <Button size="small" onClick={doMarkAllAsRead} startIcon={<Check/>}>Mark all as read</Button>:null}
</Box>
    </Popover></>
}





const NotificationsPopover = () => {
  
  
  return <NotificationsReader/>
};

export default NotificationsPopover;
