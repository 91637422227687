import { ArrowRight, Money } from "@mui/icons-material";
import { Avatar, IconButton, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { formatDistance } from "date-fns";
import { useRouter } from "next/router";
import { Briefcase, CreditCard, PieChart, User } from "react-feather";


const iconsMap =  {
  loan: CreditCard,
  savingsAccount: Money,
  loanProduct:Briefcase,
  client:User,
  savingsProduct:Briefcase,
  shareAccount:PieChart,
};

const urls = {
  loan:`/app/management/loans/`,
  savingsAccount:`/app/management/savings/`,
  shareAccount:`/app/management/shareaccounts/`,
  loanProduct:`/app/management/products/loanproducts/`,
  savingsProduct:`/app/management/products/savings/`,
  client: `/app/management/clients/`
}

export default function NotificationsList({notificationsToUse}){

  const router = useRouter();
  

  const followNotification = notification =>{
    router.push(`${urls[notification.objectType]}${notification.objectId}`);
  }

  

  return <><List disablePadding>
  {notificationsToUse.map((notification) => {
    const Icon = iconsMap[notification.objectType]?iconsMap[notification.objectType]:Settings;

    return (
      <ListItem
        divider
        key={notification.id}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText'
            }}
          >
            <Icon fontSize="small" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={(
            <Link
              color="textPrimary"
              sx={{ cursor: 'pointer' }}
              underline="none"
              variant="subtitle2"
            >
              {notification.content}
            </Link>
          )}
          secondary={`${formatDistance(
            new Date(),
            new Date(Date.UTC(
              new Date(notification.createdAt).getFullYear(),
              new Date(notification.createdAt).getMonth(),
              new Date(notification.createdAt).getDate(),
              new Date(notification.createdAt).getHours(),
              new Date(notification.createdAt).getMinutes(),
              new Date(notification.createdAt).getSeconds()
              ))
          )}  by ${notification.user?.username}`}
        />
        <ListItemSecondaryAction>
        <IconButton
            edge="end"
            title="View Details"
            onClick={()=>{followNotification(notification)}}
            size="large">
            <ArrowRight />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  })}
</List>
</>
}
