// ** React Imports
import { useEffect, useCallback, useRef, useState } from 'react'

// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import MuiDialog from '@mui/material/Dialog'
import SearchIcon from '@mui/icons-material/Search';
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme,alpha } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import MuiAutocomplete from '@mui/material/Autocomplete'



// ** Icon Imports

import Icon from '../../../components/icon'
import { Users } from 'react-feather'

const SearchButton = styled('button')(({ theme }) => [
  {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    paddingLeft: theme.spacing(0.6),
    [theme.breakpoints.only('xs')]: {
      backgroundColor: 'transparent',
      padding: 0,
      minWidth: 34,
      justifyContent: 'center',
      '& > *:not(.MuiSvgIcon-root)': {
        display: 'none',
      },
    },
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    backgroundColor: (theme.vars || theme).palette.grey[50],
    color: (theme.vars || theme).palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${(theme.vars || theme).palette.grey[200]}`,
    borderRadius: 10,
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionDuration: '150ms',
    boxShadow: `inset 0 1px 1px ${(theme.vars || theme).palette.grey[100]}, 0 1px 0.5px ${alpha(
      theme.palette.grey[100],
      0.6,
    )}`,
    '&:hover': {
      background: (theme.vars || theme).palette.grey[100],
      borderColor: (theme.vars || theme).palette.grey[300],
    },
  },
  /* theme.applyDarkStyles({
    backgroundColor: alpha(theme.palette.primary[700], 0.4),
    borderColor: (theme.vars || theme).palette.primary[700],
    boxShadow: `inset 0 1px 1px ${(theme.vars || theme).palette.primary[900]}, 0 1px 0.5px ${
      (theme.vars || theme).palette.common.black
    }`,
    '&:hover': {
      background: (theme.vars || theme).palette.primary[700],
      borderColor: (theme.vars || theme).palette.primary[600],
    },
  }), */
]);

const SearchLabel = styled('span')(({ theme }) => {
  return {
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
  };
});

const Shortcut = styled('div')(({ theme }) => {
  return {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    lineHeight: '20px',
    marginLeft: theme.spacing(0.5),
    border: `1px solid ${(theme.vars || theme).palette.grey[200]}`,
    backgroundColor: '#FFF',
    padding: theme.spacing(0, 0.5),
    borderRadius: 7,/* 
    ...theme.applyDarkStyles({
      borderColor: (theme.vars || theme).palette.primaryDark[600],
      backgroundColor: (theme.vars || theme).palette.primaryDark[800],
    }), */
  };
});



const defaultSuggestionsData = [
  {
    category: 'COMMON SHORTCUTS',
    suggestions: [
      {
        icon: "mdi:account-multiple",
        suggestion: 'Clients',
        link: '/app/management/clients'
      },
      {
        icon: 'mdi:account-group',
        suggestion: 'Groups',
        link: '/app/management/groups'
      },
      
    ]
  },
  {
    category: 'Client Accounts',
    suggestions: [
      {
        icon: 'mdi:cash-100',
        suggestion: 'Savings Accounts',
        link: '/app/management/savings'
      },
      {
        icon: 'mdi:chart-pie',
        suggestion: 'Share Accounts',
        link: '/app/management/shareaccounts'
      },
      {
        icon: 'mdi:credit-card-clock',
        suggestion: 'Client Loans / Action Tray',
        link: '/app/management/loans'
      },
      
    ]
  },
  {
    category: 'System',
    suggestions: [
      {
        icon: 'mdi:account-cog-outline',
        suggestion: 'Change Password',
        link: '/app/account'
      },
      {
        icon: 'mdi:account-multiple',
        suggestion: 'System Users',
        link: '/app/system/users'
      },
      
    ]
  },
  {
    category: 'Reports',
    suggestions: [
      {
        icon: 'mdi:monitor-dashboard',
        suggestion: 'Dashboard',
        link: '/app'
      },
      {
        icon: 'mdi:chart-bar',
        suggestion: 'Reports',
        link: '/app/reports'
      },
      
    ]
  }
]

const categoryTitle = {
  reports: 'REPORTS',
  general: 'GENERAL',
  accounting: 'ACCOUNTING',
  organization: 'ORGANIZATION',
  system: 'SYSTEM',
  shortCuts: 'SHORTCUTS',
  products:'PRODUCTS'
}

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& fieldset': {
    border: 0
  },
  '& + .MuiAutocomplete-popper': {
    '& .MuiAutocomplete-listbox': {
      paddingTop: 0,
      height: '100%',
      maxHeight: 'inherit',
      '& .MuiListSubheader-root': {
        top: 0,
        fontWeight: 400,
        lineHeight: '15px',
        fontSize: '0.75rem',
        letterSpacing: '1px',
        color: theme.palette.text.disabled
      }
    },
    '& .MuiAutocomplete-paper': {
      border: 0,
      height: '100%',
      borderRadius: 0,
      boxShadow: 'none'
    },
    '& .MuiListItem-root.suggestion': {
      padding: 0,
      '& .MuiListItemSecondaryAction-root': {
        display: 'flex'
      },
      '&.Mui-focused.Mui-focusVisible, &:hover': {
        backgroundColor: theme.palette.action.hover
      },
      '& .MuiListItemButton-root: hover': {
        backgroundColor: 'transparent'
      },
      '&:not(:hover)': {
        '& .MuiListItemSecondaryAction-root': {
          display: 'none'
        },
        '&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)': {
          '& .MuiListItemSecondaryAction-root': {
            display: 'flex'
          }
        },
        [theme.breakpoints.down('sm')]: {
          '&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root': {
            display: 'none'
          }
        }
      }
    },
    '& .MuiAutocomplete-noOptions': {
      display: 'grid',
      minHeight: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(10)
    }
  }
}))

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(4px)'
  },
  '& .MuiDialog-paper': {
    overflow: 'hidden',
    '&:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: 550
    }
  }
})

const NoResult = ({ value, setOpenDialog }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ mb: 2.5, color: 'text.primary' }}>
        <Icon icon='mdi:file-remove-outline' fontSize='5rem' />
      </Box>
      <Typography variant='h6' sx={{ mb: 11.5, wordWrap: 'break-word' }}>
        No results for{' '}
        <Typography variant='h6' component='span' sx={{ wordWrap: 'break-word' }}>
          {`"${value}"`}
        </Typography>
      </Typography>

      <Typography variant='body2' sx={{ mb: 2.5, color: 'text.disabled' }}>
        Try searching for
      </Typography>
      <List sx={{ py: 0 }}>
        <ListItem sx={{ py: 2 }} disablePadding onClick={() => setOpenDialog(false)}>
          <Box
            component={Link}
            href='/app/management/clients'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover > *': { color: 'primary.main' }
            }}
          >
            <Box sx={{ mr: 2.5, display: 'flex', color: 'text.primary' }}>
              <Users />
            </Box>
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              Client List
            </Typography>
          </Box>
        </ListItem>
        <ListItem sx={{ py: 2 }} disablePadding onClick={() => setOpenDialog(false)}>
          <Box
            component={Link}
            href='/app/account'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover > *': { color: 'primary.main' }
            }}
          >
            <Box sx={{ mr: 2.5, display: 'flex', color: 'text.primary' }}>
              <Icon icon='mdi:account-cog-outline' fontSize={20} />
            </Box>
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              Change Account Password
            </Typography>
          </Box>
        </ListItem>
        
      </List>
    </Box>
  )
}

const DefaultSuggestions = ({ setOpenDialog }) => {
  return (
    <Grid container spacing={3} sx={{ ml: 0 }}>
      {defaultSuggestionsData.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Typography component='p' variant='overline' sx={{ lineHeight: 1.25, color: 'text.disabled' }}>
            {item.category}
          </Typography>
          <List sx={{ py: 2.5 }}>
            {item.suggestions.map((suggestionItem, index2) => (
              <ListItem key={index2} sx={{ py: 2 }} disablePadding>
                <Box
                  component={Link}
                  href={suggestionItem.link}
                  onClick={() => setOpenDialog(false)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': { mr: 2.5 },
                    color: 'text.primary',
                    textDecoration: 'none',
                    '&:hover > *': { color: 'primary.main' }
                  }}
                >
                  <Icon icon={suggestionItem.icon} fontSize={20} />
                  <Typography variant='body2' sx={{ color: 'text.primary' }}>
                    {suggestionItem.suggestion}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  )
}

const AutocompleteSearchComponent = ({ hidden }) => {
  const searchButtonRef = useRef(null);
  // ** States
  const [isMounted, setIsMounted] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [openDialog, setOpenDialog] = useState(false)
  const [options, setOptions] = useState(defaultSuggestionsData)
  const searchData  = [
    {
      title:"Groups",
      description:"Member Groups",
      href:"/app/management/groups"
    },
    {
      title:"Create New User",
      description:"Add A User to the system",
      href:"/app/system/users/0/edit"
    },
    {
      title:"Closures",
      description:"Period Closures",
      href:"/app/accounting/closures"
    },
    {
      title:"Provisioning Criteria",
      description:"Manage Loan Provisioning Criteria",
      href:"/app/management/loanprovisioning",
      category:'PRODUCTS'
    },
    {
      title:"Provisioning Entries",
      description:"Manage Provisioning Entries",
      href:"/app/accounting/provisioningentries"
    },
    {
      title:"Funds",
      description:"Funds associated with loans",
      href:"/app/management/funds"
    },
    {
      title:"Client Loans/Action Tray",
      description:"Transactions that need action",
      href:"/app/management/loans"
    },
    {
      title:"Direct Loan Payment Import",
      description:"Import Payments Directly To The Loans",
      href:"/app/management/loans/paymentimport"
    },
    {
      title:"Financial Activity Mappings",
      description:"Mapings of finacial activities to GL Accounts",
      href:"/app/accounting/mappings"
    },
    {
      title:"Reports",
      description:"Search and run reports on clients and product accounts",
      href:"/app/reports"
    },
    {
      title:"Create a report",
      description:"Add a new customised report",
      href:"/app/reports/0/edit"
    },
    {
      title:"Tellers & Cashiers",
      description:"Cashier Management",
      href:"/app/management/tellers"
    },
    {
      title:"Daily Cashier Till",
      description:"Daily Cashier Till",
      href:"/app/management/tellers/till"
    },
    {
      title:"Public Holidays",
      description:"Specify action to be taken for payments due on public holidays",
      href:"/app/management/holidays"
    },
    {
      title:"Accounting Rules",
      description:"Shortcuts to posting journal entries",
      href:"/app/accounting/rules"
    },
    {
      title:"Branches",
      description:"Branch List",
      href:"/app/management/offices"
    },
    {
      title:"Accounting",
      description:"Chart Of Accounts,Accruals,..",
      href:"/app/accounting"
    },
    {
      title:"Share Accounts",
      description:"Clients Share Accounts",
      href:"/app/management/shareaccounts"
    },
    
    {
      title:"Savings Accounts",
      description:"Clients Savings Accounts",
      href:"/app/management/savings"
    },
    
    {
      title:"Organization Settings",
      description:"Organization Wide Configurations",
      href:"/app/admin/organization"
    },
    {
      title:"System Settings",
      description:"System Wide Settings",
      href:"/app/admin/system"
    },
    {
      title:"Payment Types",
      description:"Payment Types or Channels",
      href:"/app/management/paymenttypes"
    },
    
    {
      title:"Scheduled Jobs",
      description:"Tasks/Jobs scheduled to run at a particular time",
      href:"/app/system/jobs"
    },
    {
      title:"System Configurations",
      description:"Systemwide configurations",
      href:"/app/system/configs"
    },
    {
      title:"Data Tables",
      description:"Data Tables extend the system tables",
      href:"/app/system/datatables"
    },
    {
      title:"Staff",
      description:"Staff and loan Officers, employees",
      href:"/app/organization/employees"
    },
    {
      title:"External Services",
      description:"Services run by third party sysems: Email SMTP,SMS",
      href:"/app/admin/externalservices"
    },
    {
      title:"External Services: Email SMTP",
      description:"Emailing Service Settings",
      href:"/app/admin/externalservices/email"
    },
    {
      title:"Account Number Formats",
      description:"Preferences for generating account numbers for clients, savings accounts, loans etc",
      href:"/app/management/accountnumberprefs"
    },
    {
      title:"Client List",
      description:"List Of Clients",
      href:"/app/management/clients"
    },
    {
      title:"Create New Client",
      description:"Create or a new client",
      href:"/app/management/clients/0/edit"
    },
    {
      title:"Loan Product List",
      description:"List of loan products",
      href:"/app/management/products/loanproducts"
    },
    {
      title:"SMS Service Config",
      description:"SMS Service Configuration",
      href:"/app/admin/externalservices/sms"
    },
    {
      title:"Notification Service Config",
      description:"Notification Service Configuration",
      href:"/app/admin/externalservices/notification"
    },
    {
      title:"Share Product List",
      description:"List of share products",
      href:"/app/management/products/sharesproducts"
    },
    {
      title:"Amazon S3 Config",
      description:"Amazon Storage Configuration",
      href:"/app/admin/externalservices/amazons3"
    },
    {
      title:"Journal Entries",
      description:"Search Journal Entries",
      href:"/app/accounting/journalentries"
    },
    {
      title:"Create Journal Entries(Raw)",
      description:"Create Raw Journal Entries ",
      href:"/app/accounting/journalentries/rawjournalentry"
    },
    {
      title:"Chart Of Account",
      description:"List GL Accounts",
      href:"/app/accounting/coa"
    },
    {
      title:"Import Accounts",
      description:"Import GL Accounts",
      href:"/app/accounting/coa/import"
    },
    {
      title:"Import Savings",
      description:"Import Savings Transactions (Deposits)",
      href:"/app/management/savings/import"
    },
    {
      title:"Import Clients",
      description:"Import Existing Clients",
      href:"/app/management/clients/import"
    },
    {
      title:"Import Loans",
      description:"Import Existing Client Loans",
      href:"/app/management/loans/import"
    },
    {
      title:"Block Savings",
      description:"Block Savings Balances",
      href:"/app/management/savings/block"
    },
    {
      title:"Import Share Purchases",
      description:"Import Share Purchases",
      href:"/app/management/shares/import"
    },
    {
      title:"Import Withdrawals",
      description:"Import Savings Transactions (Withdrawals)",
      href:"/app/management/withdrawals/import"
    },
    {
      title:"Create Share Product",
      description:"Create a new share product",
      href:"/app/management/products/sharesproducts/0/edit"
    },
    {
      title:"Standing Order History",
      description:"History Of Standing Orders",
      href:"/app/management/standingInstructionsHistory"
    },
    {
      title:"Create Loan Product",
      description:"Create a new loan product",
      href:"/app/management/products/loanproducts/0/edit"
    },
    {
      title:"Savings Product List",
      description:"List of savings products",
      href:"/app/management/products/savingsproducts"
    },
    {
      title:"Recurring Deposits Product List",
      description:"List of fixed deposit products",
      href:"/app/management/products/recurringdeposits"
    },
    {
      title:"Create A Recurring Deposits Product",
      description:"Create a new RD Product",
      href:"/app/management/products/recurringdeposits/0/edit"
    },
    {
      title:"Create Fixed Deposit Product",
      description:"Create a new FD Product",
      href:"/app/management/products/fixeddeposits/0/edit"
    },
    {
      title:"Fixed Deposits Product List",
      description:"List of fixed deposit products",
      href:"/app/management/products/fixeddeposits"
    },
    {
      title:"Audit Search",
      description:"System Audit Trail",
      href:"/app/system/audittrail"
    },
    {
      title:"SMS Campaigns",
      description:"SMS Campaigns",
      href:"/app/management/sms"
    },
    {
      title:"Maker Checker Config",
      description:"Configure Maker-Checker Settings",
      href:"/app/system/makerChecker"
    },
    {
      title:"Create Savings Product",
      description:"Create a new savings product",
      href:"/app/management/products/savingsproducts/0/edit"
    },
    {
      title:"Charges",
      description:"List of charges for clients and products",
      href:"/app/management/charges"
    },
    {
      title:"Create Charge",
      description:"Create a charge for a product",
      href:"/app/management/charges/0/edit"
    },
    {
      title:"Codes",
      description:"List of codes - used for populating dropdown lists",
      href:"/app/management/codes"
    },
    {
      title:"Roles & Permissions",
      description:"Roles / UserGroups /Permissions",
      href:"/app/system/roles"
    },
    {
      title:"System Users",
      description:"System Users",
      href:"/app/system/users",
      category: "system"
    },
    {
      title:"Loan Product Mix",
      description:"Define how different loan products can be taken concurrently or mixed",
      href:"/app/management/products/loanproducts/mix",
      category:"products"
    },
    {
      title:"Products",
      description:"Products Menu",
      href:"/app/admin/products",
      category: "shortCuts"

    },
    
  ].map(a =>({...a,category:a.category?a.category:"general"}))

  

  // ** Hooks & Vars
  const theme = useTheme()
  const router = useRouter()
  
  const wrapper = useRef(null)
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'))

  // Get all data using API
  
  useEffect(() => {
    if (!openDialog) {
      setSearchValue('')
    }
  }, [openDialog])
  useEffect(() => {
    setIsMounted(true)

    return () => setIsMounted(false)
  }, [])

  // Handle click event on a list item in search result
  const handleOptionClick = obj => {
    setSearchValue('')
    setOpenDialog(false)
    if (obj.href) {
      router.push(obj.href)
    }
  }

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback(
    event => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (!openDialog && event.ctrlKey && event.which === 191) {
        setOpenDialog(true)
      }
    },
    [openDialog]
  )

  // Handle shortcut keys keyup events
  const handleKeyUp = useCallback(
    event => {
      // ** ESC key to close searchbox
      if (openDialog && event.keyCode === 27) {
        setOpenDialog(false)
      }
    },
    [openDialog]
  )
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp, handleKeydown])
  if (!isMounted) {
    return null
  } else {
    return (
      <Box
        ref={wrapper}
        onClick={() => !openDialog && setOpenDialog(true)}
        sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
      >
        <SearchButton
        ref={searchButtonRef}
        onClick={()=>{setOpenDialog(true)}}
        aria-labelledby="app-search-label"
        
      >
        <SearchIcon
          fontSize="small"
          sx={(theme) => ({
            color: 'primary.500',
            /* ...theme.applyDarkStyles({
              color: 'primary.300',
            }), */
          })}
        />
        <SearchLabel id="app-search-label">Search..</SearchLabel>
        <Shortcut aria-hidden="true">
          
          {'Ctrl+'}/
        </Shortcut>
      </SearchButton>
        {openDialog && (
          <Dialog fullWidth open={openDialog} fullScreen={fullScreenDialog} onClose={() => setOpenDialog(false)}>
            <Box sx={{ top: 0, width: '100%', position: 'sticky' }}>
              <Autocomplete
                autoHighlight
                disablePortal
                options={searchData}
                id='appBar-search'
                isOptionEqualToValue={() => true}
                onInputChange={(event, value) => setSearchValue(value)}
                onChange={(event, obj) => handleOptionClick(obj)}
                noOptionsText={<NoResult value={searchValue} setOpenDialog={setOpenDialog} />}
                getOptionLabel={option => option.title || ''}
                groupBy={option => (searchValue.length ? categoryTitle[option.category] : '')}
                sx={{
                  '& + .MuiAutocomplete-popper': {
                    ...(searchValue.length
                      ? {
                          overflow: 'auto',
                          maxHeight: 'calc(100vh - 69px)',
                          borderTop: `1px solid ${theme.palette.divider}`,
                          height: fullScreenDialog ? 'calc(100vh - 69px)' : 481,
                          '& .MuiListSubheader-root': { p: theme.spacing(2, 3, 0.75) }
                        }
                      : {
                          '& .MuiAutocomplete-listbox': { pb: 0 }
                        })
                  }
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      placeholder='Search..'
                      value={searchValue}
                      onChange={event => setSearchValue(event.target.value)}
                      inputRef={input => {
                        if (input) {
                          if (openDialog) {
                            input.focus()
                          } else {
                            input.blur()
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: { p: `${theme.spacing(2, 3)} !important` },
                        startAdornment: (
                          <InputAdornment position='start' sx={{ color: 'text.primary' }}>
                            <Icon icon='mdi:magnify' />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            onClick={() => setOpenDialog(false)}
                            sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                          >
                            {!hidden ? <Typography sx={{ mr: 2.5, color: 'text.disabled' }}>[esc]</Typography> : null}
                            <IconButton size='small' sx={{ p: 1 }}>
                              <Icon icon='mdi:close' fontSize={20} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )
                }}
                renderOption={(props, option) => {
                  return searchValue.length ? (
                    <ListItem
                      {...props}
                      key={option.title}
                      className={`suggestion ${props.className}`}
                      onClick={() => handleOptionClick(option)}
                      secondaryAction={<Icon icon='mdi:subdirectory-arrow-left' fontSize={20} />}
                      sx={{
                        '& .MuiListItemSecondaryAction-root': {
                          '& svg': {
                            cursor: 'pointer',
                            color: 'text.disabled'
                          }
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{
                          py: 2.5,
                          px: `${theme.spacing(6)} !important`,
                          '& svg': { mr: 2.5, color: 'text.primary' }
                        }}
                      >
                        <Icon fontSize={20} icon={option.icon || 'mdi:circle-outline'} />
                        <Typography variant='body2' sx={{ color: 'text.primary' }}>
                          {option.title}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ) : null
                }}
              />
            </Box>
            {searchValue.length === 0 ? (
              <Box
                sx={{
                  p: 6,
                  display: 'grid',
                  overflow: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderTop: `1px solid ${theme.palette.divider}`,
                  height: fullScreenDialog ? 'calc(100vh - 69px)' : '100%'
                }}
              >
                <DefaultSuggestions setOpenDialog={setOpenDialog} />
              </Box>
            ) : null}
          </Dialog>
        )}
      </Box>
    )
  }
}

export default AutocompleteSearchComponent
