import { useEffect, useMemo, useState } from "react";

import { experimentalStyled } from "@mui/material/styles";

import DashboardSidebar from "./DashboardSidebar";
import AuthGuard from "src/components/AuthGuard";
import useAuth from "src/hooks/useAuth";
import { useGetBusinessDateQuery, useGetConfigurationDetailsQuery, useLazyGetUserDetailsQuery } from "src/services/api";
import DashboardNavbar from "./DashboardNavbar";
import useSettings from "src/hooks/useSettings";


const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px",
  },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout = ({ children }) => {
  const [getUserDetails] = useLazyGetUserDetailsQuery()
  const {user,userDetails,setUserDetails} = useAuth();
  const {setBusinessDate,setBusinessDateEnabled} = useSettings();
  const {data:enableBusinessDate} = useGetConfigurationDetailsQuery("enable_business_date")
  const {data:businessDate} = useGetBusinessDateQuery()
  

  useMemo(()=>{
    if(enableBusinessDate){
      setBusinessDateEnabled(enableBusinessDate.enabled)
    }
    if(businessDate){
      setBusinessDate(businessDate.date)
    }
  },[enableBusinessDate,businessDate,setBusinessDate,setBusinessDateEnabled])
  
  useEffect(()=>{
    if(user){
      if(!userDetails){getUserDetails(user.userId).unwrap().then(a => setUserDetails(a))}
    }
    
  },[user,userDetails,getUserDetails,setUserDetails])
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  
  

  


  return (
    <AuthGuard>
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{children}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
    </AuthGuard>
  );
};

export default DashboardLayout;
