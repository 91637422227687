import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  
  Box,
  ClickAwayListener,
  
  SvgIcon,
  List,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';


import { Search } from 'react-feather';
import ClientItem from './ClientItem';

const ClientSearch = forwardRef(
  (
    {
      className,
      isFocused,
      onChange,
      onClickAway,
      onFocus,
      onSelect,
      query,
      results,
      ...rest
    },
    ref
  ) => {
    

    const displayResults = query && isFocused;

    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <div  ref={ref} {...rest}>
          <div >

            
            
            
            <TextField
              fullWidth
disableUnderline
onChange={onChange}
onFocus={onFocus}
placeholder="Search Names, Acc Numbers"
value={query}
InputProps={{
  startAdornment: (
    <InputAdornment position="start">
      <SvgIcon fontSize="small" color="action">
        <Search />
      </SvgIcon>
    </InputAdornment>
  )
}}

            
            />
          </div>
          {displayResults && (
            <Box mt={2}>
              <Typography variant="h6" color="textSecondary">
                Matching Clients
              </Typography>
              <List>
                {results.map((client) => {
                  return (
                    <ClientItem client={client} key={client.id}/>
                  );
                })}
              </List>
            </Box>
          )}
        </div>
      </ClickAwayListener>
    );
  }
);

ClientSearch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClickAway: PropTypes.func,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  query: PropTypes.string,
  results: PropTypes.array
};

ClientSearch.defaultProps = {
  isFocused: false,
  query: '',
  results: []
};

ClientSearch.displayName="ClientSearch"

export default ClientSearch;
