
import NextLink from 'next/link';
import {
  IconButton,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import {
  BarChart2 as BarChartIcon
} from 'react-feather';





const Reports = () => {
  
  return <>
    <NextLink href="/app/reports" passHref legacyBehavior>
      <Tooltip title="Reports">
        <IconButton color="inherit" size="large">
          <SvgIcon>
            <BarChartIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </NextLink>
  </>;
};

export default Reports;
