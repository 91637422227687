import React, { useRef, useState } from 'react';
import NextLink from 'next/link';
import { IconButton, List, Link, ListItem, ListItemText, Popover, SvgIcon, Tooltip } from '@mui/material';



import { Security } from '@mui/icons-material';



const Organization = () => {
  
  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title="Admin">
      <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
        <SvgIcon>
          <Security />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: { width: 240 }
      }}
    >
      <>
        <List disablePadding>
          <ListItem divider>
            <NextLink href="/app/system/users" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Users"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="/app/admin/organization" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Organization"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="/app/admin/system" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="System"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="/app/admin/products" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Products"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="/app/admin/externalservices" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="External Services"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="/app/admin/bulkimport" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Bulk Import"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
        </List>
      </>
    </Popover>
  </>;
};

export default Organization;
