
import NextLink from 'next/link';
import {
  IconButton,
  SvgIcon,
  Tooltip,
} from '@mui/material';

import {
  Inbox
} from 'react-feather';





const InboxTray = () => {
  

  

  

  return <>
    <NextLink href="/app/management/loans" passHref legacyBehavior>
      <Tooltip title="Inbox Tray - Action Required">
        <IconButton color="inherit" size="large">
          <SvgIcon>
            <Inbox />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </NextLink>
  </>;
};

export default InboxTray;
