import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';



const BHALogin = ({ className, ...rest }) => {
  
  const { login, isLoading, loginError } = useAuth();
  const isMountedRef = useIsMountedRef();

  const [showPassword,setShowPassword] = useState(false)

  const toggleShowPassword = () =>{
    setShowPassword(!showPassword)
  }
  
  useEffect(() => {
    if (loginError) {
      console.log(loginError);
    }
  }, [loginError]);

  return (
    <Formik
      initialValues={{
        submit: null
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required('Username is required'),
        password: Yup.string().max(255).required('Password is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.username, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.username && errors.username)}
            fullWidth
            autoFocus
            helperText={touched.username && errors.username}
            label="Username"
            margin="normal"
            required
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.username}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            required
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword?"text":"password"}
            value={values.password}
            InputProps={{endAdornment: <IconButton onClick={toggleShowPassword}>{showPassword?<VisibilityOff/>:<Visibility/>}</IconButton>}}
            variant="outlined"
          />
          {loginError && (
            <Box mt={3}>
              <FormHelperText error>
                {loginError.data
                  ? loginError.data.defaultUserMessage
                  : 'Invalid Login'}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting || isLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {isLoading ? 'Logging In' : 'Login'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};



export default BHALogin;
