import { useCallback, useEffect, useMemo } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import BriefcaseIcon from "../../icons/Briefcase";
import UsersIcon from "../../icons/Users";

import NavSection from "./NavBar/NavSection";
import Scrollbar from "../../components/Scrollbar";
import getInitials from "src/utils/getInitials";
import {
  GitBranch as BranchIcon,
  DollarSign as DollarSignIcon,
  List as ListIcon,
  Users as Groups,
  PieChart as PieChartIcon,
  BarChart,
} from 'react-feather';
import { Money } from "@mui/icons-material";
import { format } from "date-fns";
import useSettings from "src/hooks/useSettings";

const sections = [
  {
    title: 'Quick Links',
    items: [
      {
        title: 'Dashboard',
        icon: <PieChartIcon/>,
        href: '/app'
      },
      {
        title: 'Financials',
        icon: <BarChart/>,
        href:'/app/reports/',
        children: [
          {
            title: 'Balance Sheet',
            href: '/app/reports/balance_sheet'
          },
          {
            title: 'Income Statement',
            href: '/app/reports/income_statement'
          },
        ]
      },
      {
        title: 'Journal Entries',
        icon: <Money/>,
        href: '/app/accounting/journalentries'
      },
      {
        title: 'Customers',
        icon: <UsersIcon/>,
        href: '/app/management/clients',
        children: [
          {
            title: 'Customer List',
            href: '/app/management/clients'
          },
          {
            title: 'Add Customer',
            href: '/app/management/clients/0/edit'
          },
          {
            title: 'Import Customers',
            href: '/app/management/clients/import'
          }
        ]
      },

      {
        title: 'Groups',
        icon: <Groups/>,
        href: '/app/management/groups',
        children: [
          {
            title: 'Group List',
            href: '/app/management/groups'
          },
        ]
      },
      {
        title: 'Loan Applications',
        icon: <ListIcon/>,
        href: '/app/management/loans',
        children:[
          {
            title: 'Loan Applications',
            href: '/app/management/loans'
          },
          {
            title: 'Import Loan Applications',
            href: '/app/management/loans/import'
          },
        ]
      },
      {
        title: 'Share Accounts',
        icon: <PieChartIcon/>,
        href: '/app/management/shareaccounts'
      },
      {
        title: 'Savings Accounts',
        icon: <DollarSignIcon/>,
        href: '/app/management/savings',
        children: [
          {
            title: 'Savings Accounts',
            href: '/app/management/savings'
          },
          {
            title: 'Standing Instructions Run History',
            href: '/app/management/instructionRunHistory'
          },
          {
            title: 'Import Savings',
            href: '/app/management/savings/import'
          },
          {
            title: 'Import Withdrawals',
            href: '/app/management/savings/withdrawals/import'
          }
        ]
      },
      {
        title: 'Branches',
        icon: <BranchIcon/>,
        href: '/app/management/offices',
        children: [
          {
            title: 'Branches',
            href: '/app/management/offices'
          },
          
        ]
      },
      {
        title: 'Loan Products',
        icon: <BriefcaseIcon/>,
        href: '/app/management/products',
        children: [
          {
            title: 'Loan Product List',
            href: '/app/management/products/loanproducts'
          },
          {
            title: 'Add New Loan Product',
            href: '/app/management/products/loanproducts/0/edit'
          }
        ]
      },
      {
        title: 'Savings Products',
        icon: <BriefcaseIcon/>,
        href: '/app/management/savings',
        children: [
          {
            title: 'Savings Product List',
            href: '/app/management/products/savingsproducts'
          },
          {
            title: 'Add New Savings Product',
            href: '/app/management/products/savingsproducts/0/edit'
          }
        ]
      },
      {
        title: 'Shares Products',
        icon: <BriefcaseIcon />,
        href: '/app/management/shares',
        children: [
          {
            title: 'Shares Product List',
            href: '/app/management/shares'
          },
          {
            title: 'Add New Share Product',
            href: '/app/management/products/sharesproducts/0/edit'
          }
        ]
      }
    ]
  },
  ]


const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useRouter();
  const {settings,isBusinessDateEnabled,businessDate} = useSettings();
  const {dateFormat} = settings
  const { user,userDetails } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));


  

  
  

  /* useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    
    
      
  }, [
    openMobile,onMobileClose
  ]); */

  

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        //textDecoration:"none"
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          

            
            

          
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <NextLink href="/dashboard/account" passHref legacyBehavior>
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              >{userDetails?getInitials(`${userDetails.firstname} ${userDetails.lastname}`):getInitials(user.username)}</Avatar>
            </NextLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {userDetails?`${userDetails.firstname} ${userDetails.lastname}`:user.username}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Branch: {user.officeName}
                
              </Typography>
            </Box>
          </Box>
          {isBusinessDateEnabled? <Box>
            
            {businessDate? <Alert severity="info" sx={{mt:2}}>{`Business Date: ${format(new Date(businessDate),dateFormat)}`}</Alert>: <Alert severity="warning" sx={{mt:2}}>Business Date not set</Alert>}
          
          </Box>:null}
           
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Reach Out To Us
          </Typography>
          
            <Button
              LinkComponent={"a"}
              href="tel:+256756667445"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
            >
              Help Desk
            </Button>
          
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
          top:32
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
