import { useRef, useState } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";



import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";


import useAuth from "src/hooks/useAuth";
import { LogOut, Settings } from "react-feather";
import getInitials from "src/utils/getInitials";
import { useSnackbar } from "notistack";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout,userDetails } = useAuth();
  const navigate = useRouter();
  const {enqueueSnackbar} = useSnackbar()

  

  
  
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout.",{variant:"error"});
    }
  };

  return <>
    <Box
      component={ButtonBase}
      onClick={handleOpen}
      ref={anchorRef}
      sx={{
        alignItems: "center",
        display: "flex",
      }}
    >
      <Avatar
        sx={{
          height: 32,
          width: 32,
        }}
      >
        {userDetails?getInitials(`${userDetails.firstname} ${userDetails.lastname}`):getInitials(user.username)}
      </Avatar>
    </Box>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 240 },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="subtitle2">
          {userDetails?`${userDetails.firstname} ${userDetails.lastname}`:getInitials(user.username)}
        </Typography>
        <Typography color="textSecondary" variant="subtitle2">
          {user?.officeName}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{mt:2}}>
      <NextLink href="/app/account" passHref legacyBehavior>
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Change Password
                </Typography>
              }
            />
          </MenuItem>
        </NextLink>

      </Box>
      
      <Box sx={{ p: 2 }}>
        <Button
          startIcon={<LogOut/>}
          color="primary"
          fullWidth
          onClick={handleLogout}
          variant="outlined"
        >
          Logout
        </Button>
      </Box>
    </Popover>
  </>;
};

export default AccountPopover;
