import { useRef, useState} from 'react';
import NextLink from 'next/link';
import { IconButton, List, Link, ListItem, ListItemText, Popover, SvgIcon, Tooltip } from '@mui/material';
import { Business } from '@mui/icons-material';




const Institution = () => {
  
  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Tooltip title="Institution">
      <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
        <SvgIcon>
          <Business />
        </SvgIcon>
      </IconButton>
    </Tooltip>
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: { width: 240 }
      }}
    >
      <>
        <List disablePadding>
          <ListItem divider>
            <NextLink href="/app/management/clients" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Clients"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="/app/management/groups" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Groups"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
          <ListItem divider>
            <NextLink href="#" passHref legacyBehavior>
              <Link underline="none">
                <ListItemText
                  primary="Centers"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                />
              </Link>
            </NextLink>
          </ListItem>
        </List>
      </>
    </Popover>
  </>;
};

export default Institution;
