import PropTypes from 'prop-types';
import NextLink from 'next/link';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import getInitials from 'src/utils/getInitials';
import { useTheme } from '@mui/material';



const useStyles = makeStyles(() => ({
  root: {}
}));

const ClientItem = ({ className, client, ...rest }) => {
  const classes = useStyles();

  const theme = useTheme()

  const borderColors = {
    100: theme.palette.warning.main,
    300: theme.palette.success.main,
    600: theme.palette.grey[700]
  }

  
  

  return (
    <ListItem disableGutters >
      <ListItemAvatar>
        <Avatar alt="Client" src={client.image} style={{backgroundColor:borderColors[client.status.id]}} >
          {getInitials(client.displayName)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.listItemText}
        disableTypography
        primary={
          <NextLink
            href={
              client.isGroup
                ? `/app/management/groups/${client.id}`
                : `/app/management/clients/${client.id}`
            }
            passHref
            legacyBehavior>
            <Link
              color="textPrimary"
              display="block"
              noWrap
              underline="none"
              variant="subtitle2"
            >
              {client.displayName
                ? client.displayName
                : client.firstname + ' ' + client.lastname}
            </Link>
          </NextLink>
        }
        secondary={<Typography color="textSecondary"
        noWrap
        variant="caption" >{client.mobileNo}</Typography>}
      />
      <Typography
                      color="textSecondary"
                      noWrap
                      variant="caption"
                    >
                      {client.externalId}
                    </Typography>
      
    </ListItem>
  );
};

ClientItem.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired
};

export default ClientItem;
